export const ver = 'T7398'
import { router } from "@/router"
// import { Api as $api } from '@/api'
import { Toast } from "vant"

export const verData = {
  T7398: {
    title: 'Tiktoker',
    loginIcon: "T7399_login_icon",
    moneyType: 'USDT',
    needBank: true,
    areaCode: 1,
    lan: 'en',
    needCode: true,
    url: 'https://tiktoker.cyou/',
    bankInput: true,
    vipHide: false,
    bankCardLimit: 11,
    levelType: 'recharge',
    newRecharge: true, // 新版本修改后新增参数
    useNewMine: true,
    allowedMoreCard: true,
    pathNewRecharge: true, // 跳转新的充值页面
    homeProfitInterval: [10, 5000],
    homeProfitIntervalMax: [11500, 11999],
    useNewWithdraw: true,
    theme: "#FFD700",
    themes: 'dark',
    navBg: "#000",
    btnColor: '#424242',
    noticeIconColor: "#000",
    selectCurrency: true,
    homeShowTradeBar: true,
    homeShowAboutBar: true,
    useIngegrante: true,
    homeShowInvite: true,
    mineHasCommissionRecords: true,
    hasManyBank: true,
    // mineServiceTools: {
    //   bgColor: "#e7f1fd",
    //   data: [
    //     {
    //       title: "mine.serviceTools.certify",
    //       icon: "icon-cardid",
    //       func: () => {
    //         router.push("/Certification");
    //       },
    //     },
    //     {
    //       title: "mine.serviceTools.personal",
    //       icon: "icon-wode1",
    //       func: () => {
    //         router.push("PersonalCenter");
    //       },
    //       fontSize: 26
    //     },
    //     {
    //       title: "mine.serviceTools.invite",
    //       icon: "icon-yaoqing",
    //       func: () => {
    //         router.push("inviteFriends");
    //       },
    //     },
    //     {
    //       title: "login.userAgreement",
    //       icon: "icon-xiugai",
    //       fontSize: 20,
    //       func: () => {
    //         router.push("/UserAgreement");
    //       },
    //     },
    //     {
    //       title: "mine.serviceTools.aboutUs",
    //       icon: "icon-gongsidizhi",
    //       fontSize: 20,
    //       func: () => {
    //         router.push("Aboutus");
    //       },
    //     },
    //     {
    //       title: "My.service",
    //       icon: "icon-kefu",
    //       fontSize: 26,
    //       func: () => {
    //         $api.toService();
    //       },
    //     },
    //     // 切换语言
    //     {
    //       title: "mine.serviceTools.lan",
    //       icon: "icon-yuyan",
    //       isSwitchLans: true,
    //     },
    //     {
    //       title: "My.download",
    //       icon: "icon-xiazaidaoru",
    //       func: () => {
    //         if (localStorage.getItem("android")) {
    //           location.href = localStorage.getItem("android");
    //         } else {
    //           location.href = "http://www.baidu.com";
    //         }
    //       },
    //     },
    //   ]
    // },
    tabbar: {
      selectedColor: "#FFD700",
      // type: "image",
      pages: [
        {
          text: 'newTab.home',
          type: 'image',
          pagePath: 'goods',
          component: () => import("@/view/goods"),
          iconPath: require('@/assets/Img/tabber/home.png'),
          selectedIconPath: require('@/assets/Img/tabber/home_active.png'),
          isSpecial: false,
        },
        // {
        //   text: `${'page.Recharge'}`,
        //   type: 'image',
        //   pagePath: 'gift',
        //   component: () => import("@/view/bank/gift"),
        //   iconPath: require('@/assets/Img/tabber/gift.png'),
        //   selectedIconPath: require('@/assets/Img/tabber/gift_active.png'),
        //   isSpecial: false,
        // },
        {
          type: 'image',
          text: `${'newTab.order'}`,
          pagePath: 'cart',
          component: () => import('@/view/cart/index'),
          iconPath: require('@/assets/Img/tabber/gift.png'),
          selectedIconPath: require('@/assets/Img/tabber/gift_active.png'),
          isSpecial: false,
        },
        {
          text: `${'page.Recharge'}`,
          type: 'image',
          pagePath: 'gift',
          component: () => import("@/view/bank/gift"),
          // iconPath: "icon-dianji",
          // selectedIconPath: "icon-a-28Cdianji",
          iconPath: require('@/assets/Img/tabber/tabbar_3.gif'),
          selectedIconPath: require('@/assets/Img/tabber/tabbar_3_active.gif'),
          isSpecial: true,
        },
        // {
        //   type: 'image',
        //   text: `${'tab.make_money'}`,
        //   pagePath: 'make-money',
        //   component: () => import('@/view/new/make-money'),
        //   // iconPath: "icon-dianji",
        //   // selectedIconPath: "icon-a-28Cdianji",
        //   iconPath: require('@/assets/Img/tabber/tabbar_3.gif'),
        //   selectedIconPath: require('@/assets/Img/tabber/tabbar_3_active.gif'),
        //   isSpecial: true,
        // },
        // {
        //   text: `${'newTab.order'}`,
        //   pagePath: 'cart',
        //   component: () => import('@/view/cart'),
        //   iconPath: "icon-dingdanyichenggong",
        //   selectedIconPath: "icon-complete-fill",
        //   isSpecial: false,
        // },
        {
          type: 'image',
          text: 'newTab.p2p',
          pagePath: 'p2p',
          component: () => import('@/view/p2p'),
          iconPath: require('@/assets/Img/tabber/p2p.png'),
          selectedIconPath: require('@/assets/Img/tabber/p2p_active.png'),
          isSpecial: false,
        },
        {
          type: 'image',
          text: `${'newTab.my'}`,
          pagePath: 'user',
          component: () => import('@/view/user'),
          iconPath: require('@/assets/Img/tabber/user.png'),
          selectedIconPath: require('@/assets/Img/tabber/user_active.png'),
          isSpecial: false,
        },
      ]
    },
    nullPng: require("@/assets/Img/null/T7399.png"),
    unreadBg: "#fcc400",
    unreadColor: "#000",
    homeTheme: "#0073e4"
  },
  T7398_test: {
    title: 'Tiktoker',
    loginIcon: "T7399_login_icon",
    moneyType: 'USDT',
    needBank: true,
    areaCode: 1,
    lan: 'en',
    needCode: true,
    url: 'https://gt7398.gtzs.cc/',
    bankInput: true,
    vipHide: false,
    bankCardLimit: 11,
    levelType: 'recharge',
    newRecharge: true, // 新版本修改后新增参数
    useNewMine: true,
    allowedMoreCard: true,
    pathNewRecharge: true, // 跳转新的充值页面
    homeProfitInterval: [10, 5000],
    homeProfitIntervalMax: [11500, 11999],
    useNewWithdraw: true,
    theme: "#FFD700",
    themes: 'dark',
    navBg: "#000",
    btnColor: '#424242',
    noticeIconColor: "#000",
    selectCurrency: true,
    homeShowTradeBar: true,
    homeShowAboutBar: true,
    useIngegrante: true,
    homeShowInvite: true,
    mineHasCommissionRecords: true,
    hasManyBank: true,
    // mineServiceTools: {
    //   bgColor: "#e7f1fd",
    //   data: [
    //     {
    //       title: "mine.serviceTools.certify",
    //       icon: "icon-cardid",
    //       func: () => {
    //         router.push("/Certification");
    //       },
    //     },
    //     {
    //       title: "mine.serviceTools.personal",
    //       icon: "icon-wode1",
    //       func: () => {
    //         router.push("PersonalCenter");
    //       },
    //       fontSize: 26
    //     },
    //     {
    //       title: "mine.serviceTools.invite",
    //       icon: "icon-yaoqing",
    //       func: () => {
    //         router.push("inviteFriends");
    //       },
    //     },
    //     {
    //       title: "login.userAgreement",
    //       icon: "icon-xiugai",
    //       fontSize: 20,
    //       func: () => {
    //         router.push("/UserAgreement");
    //       },
    //     },
    //     {
    //       title: "mine.serviceTools.aboutUs",
    //       icon: "icon-gongsidizhi",
    //       fontSize: 20,
    //       func: () => {
    //         router.push("Aboutus");
    //       },
    //     },
    //     {
    //       title: "My.service",
    //       icon: "icon-kefu",
    //       fontSize: 26,
    //       func: () => {
    //         $api.toService();
    //       },
    //     },
    //     // 切换语言
    //     {
    //       title: "mine.serviceTools.lan",
    //       icon: "icon-yuyan",
    //       isSwitchLans: true,
    //     },
    //     {
    //       title: "My.download",
    //       icon: "icon-xiazaidaoru",
    //       func: () => {
    //         if (localStorage.getItem("android")) {
    //           location.href = localStorage.getItem("android");
    //         } else {
    //           location.href = "http://www.baidu.com";
    //         }
    //       },
    //     },
    //   ]
    // },
    tabbar: {
      selectedColor: "#FFD700",
      // type: "image",
      pages: [
        {
          text: 'newTab.home',
          type: 'image',
          pagePath: 'goods',
          component: () => import("@/view/goods"),
          iconPath: require('@/assets/Img/tabber/home.png'),
          selectedIconPath: require('@/assets/Img/tabber/home_active.png'),
          isSpecial: false,
        },
        {
          text: `${'page.Recharge'}`,
          type: 'image',
          pagePath: 'gift',
          component: () => import("@/view/bank/gift"),
          iconPath: require('@/assets/Img/tabber/gift.png'),
          selectedIconPath: require('@/assets/Img/tabber/gift_active.png'),
          isSpecial: false,
        },
        {
          type: 'image',
          text: `${'tab.make_money'}`,
          pagePath: 'make-money',
          component: () => import('@/view/new/make-money'),
          // iconPath: "icon-dianji",
          // selectedIconPath: "icon-a-28Cdianji",
          iconPath: require('@/assets/Img/tabber/tabbar_3.gif'),
          selectedIconPath: require('@/assets/Img/tabber/tabbar_3_active.gif'),
          isSpecial: true,
        },
        // {
        //   text: `${'newTab.order'}`,
        //   pagePath: 'cart',
        //   component: () => import('@/view/cart'),
        //   iconPath: "icon-dingdanyichenggong",
        //   selectedIconPath: "icon-complete-fill",
        //   isSpecial: false,
        // },
        {
          type: 'image',
          text: 'newTab.p2p',
          pagePath: 'p2p',
          component: () => import('@/view/p2p'),
          iconPath: require('@/assets/Img/tabber/p2p.png'),
          selectedIconPath: require('@/assets/Img/tabber/p2p_active.png'),
          isSpecial: false,
        },
        {
          type: 'image',
          text: `${'newTab.my'}`,
          pagePath: 'user',
          component: () => import('@/view/user'),
          iconPath: require('@/assets/Img/tabber/user.png'),
          selectedIconPath: require('@/assets/Img/tabber/user_active.png'),
          isSpecial: false,
        },
      ]
    },
    nullPng: require("@/assets/Img/null/T7399.png"),
    unreadBg: "#fcc400",
    unreadColor: "#000",
    homeTheme: "#0073e4"
  },
}

export default verData[ver]
