export default {
  // 公共的key
  "common": {
    "unrealized": "غير مكتمل",
    "all": "الجميع",
    "distributionTip": "$ 0 لـ 0 الشحن",
    "easeBack": "لا تقلق",
    "addCarSuccess": "وضعت بنجاح في عربة التسوق",
    "deleteConfirm": "هل ترغب حقًا في حذف العنصر المحدد؟"
  },
  //商品详情
  "goodsDetail": {
    "goodsDetail": "تفاصيل المنتج",
    "shopping": "أدخل",
    "end": "نهاية المسافة",
    "sold": "مُباع",
    "goodsTips": "تبلغ قيمة هذه المقالة 500 جم/مقالة بقيمة أقل من 500 غرام وأسعار أخرى.",
    "quickTime": "الأسرع التي يتم الوصول إليها في 29 دقيقة",
    "specifications": "تفاصيل",
    "preservationConditions": "حفظ الظروف",
    "coldStorage": "مبردة",
    "shelfLife": "تاريخ الانتهاء",
    "day": "15 يوم",
    "addToCar": "أدخل عربة التسوق"
  },
  "car": {
    "title": "سيارة",
    "delete": "حذف",
    "tip": "عربة التسوق فارغة ~",
    "walk": "تعال ، سنحتفل",
    "all": "اختيار",
    "guess": "أعتقد أنك ستعجبك."
  },
  "home": {
    "HallofFame": "مجد",
    "chooseLocation": "الرجاء تحديد موقع ..",
    "more": "أكثر",
    "home": "الصفحة الرئيسية",
    "category": "فرز",
    "eat": "كل شيئا؟",
    "cart": "سيارة",
    "mine": "لي",
    "inputGoodsName": "أدخل اسم العنصر.",
    "vipDesc": "من المتوقع مشاركة الأعضاء.يوفر حوالي 806 دولار في السنة",
    "vipSubDesc": "خصم 50 ٪>",
    "specialZone": "منطقة خاصة",
    "foodie": "أكل يوم",
    "dinner": "عشاء",
    "popularity": "شعبية",
    "goodChoose": "اختيار",
    "limitBuy": "محدود",
    "bottomTip": "لدي حد",
    "chooseAddress": "الرجاء تحديد عنوان",
    "currentLocation": "وضعك الحالي",
    "nearbyAddress": "قريب من"
  },
  // 分类
  "category": {
    "tip": "أدخل اسم العنصر.",
    "bottomTip": "حسنًا ، لقد جئنا إلى اللاعبين الآخرين."
  },
  // 吃什么
  "eat": {
    "all": "الجميع",
    "close": "تبني",
    "pleaseHolder": "ابحث عن مكونات الوصفة",
    "menu": "قائمة اليوم",
    "menuDevide": "قائمة الفرز",
    "recent": "وجدت للتو",
    "tip_bottom": "لقد انتهى الأمر للتوقف عن السحب!",
    "tip": "لم يتم حفظ المجموعة بعد ~"
  },
  // 我的
  "mine": {
    "title": "لي",
    "phone": "رقم هاتف",
    "login": "تسجيل الدخول الآن",
    "myOrder": "طلبي",
    "allOrder": "إظهار جميع العناصر المطلوبة",
    "myBill": "قسيم",
    "myLocation": "عنوان الاختيار الخاص بي",
    "myCar": "بطاقتي الخضراء",
    "servier": "اتصل بدعم العملاء",
    "servierTime": "خدمة العملاء 07.00-22: 00",
    "feedback": "تعليق",
    "switchLanguage": "لغة",
    "chinese": "صينى",
    "english": "إنجليزي",
    "version": "النموذج الحالي",
    "waitingPay": "نفقات لاحقة",
    "waitingReceive": "الحصول على البضائع",
    "waitingComment": "يقيم",
    "waitingFeedback": "بعد البيع/السداد",
    "unrealized": "لم يتم فتح صفحة السداد بعد!",
    "tip2": "لا تنس أن تطلب نجمًا صغيرًا ❤ ~",
    "tip3": "💘 شكرا لك على اهتمامك 💘",
    "tip4": "ابحث في النادي",
    "feedbackTip": "المهووسين جيمس/ddbuy",
    "cardPrise": "🦉 سيأتي لعروض التكيف 🙉",
    "moreMessage": "لا توجد أوامر حاليًا",
    "openCard": "سعر Greencard",
    "recomend": "ممتن لمقابلتك...",
    "cardTip1": "افتح البطاقة الخضراء",
    "cardTip2": "تكلف جميع البطاقات الخضراء 6.66 دولار.",
    "cardTip3": "بطاقة تعريف",
    "cardTip4": "حصري",
    "vipType": "متكامل مزدوج",
    "vipTime": "امتياز",
    "vipRecommend": "خصم 50",
    "prise1": "سنة 365 يومًا",
    "prise2": "يتوافق مع 0.24 دولار في اليوم",
    "vipType1": "¥ 88",
    "vipTime1": "¥ 188",
    "vipRecommend1": "خصم 70 ٪",
    "prise11": "بطاقة موسم 90 يومًا",
    "prise12": "حوالي 0.33 دولار في اليوم",
    "payMethod": "¥ 30",
    "wechatPay": "¥ 45",
    "aliPay": "إجراءات الدفع",
    "huabeiPay": "الدفع عن طريق البريد",
    "payImmeatally": "ودفع المال",
    "cardUeless": "دفع",
    "tip": "الدفع الفوري",
    "changeNickName": "لم يتم فتح البطاقة الخضراء بعد.",
    "confirm": "هذا كل شئ!لا أستطيع ~~",
    "nickName": "كنية",
    "personal": "تسجيل",
    "message": "يرجى تعيين لقب جديد.",
    "myBills": "المعلومات الشخصية التي تم تحريرها بنجاح!",
    "persent": "كنية",
    "bill": "قسيم",
    "condition": "يرجى الضغط على الرقم 520 ...",
    "rmb": "قسيمة",
    "myOrders": "لا موجة دائمة",
    "itemsTitle": "خصم إضافي قدره 1.5 دولار",
    "personalInfo": "يوان",
    "head": "طلبي",
    "user_name": "جميع الطبعات اللاحقة ، استلام الكائنات للتسجيل",
    "userSex": "معلومات شخصية",
    "Brithday": "رأس",
    "phoneNumber": "كنية",
    "noInput": "الجنس",
    "logout": "عيد ميلاد",
    "infoTip": "رقم هاتف",
    "loginInfo": "لا تملأ",
    "infoSuccess": "تسجيل خروج",
    "year": "تم تحرير المعلومات الشخصية بنجاح",
    "month": "هل تريد حقا تسجيل الخروج؟",
    "day": "اسحب للخلف!",
    "man": "سنة",
    "woman": "شهر",
    "greenCard": "يوم - نهار",
    "greenCardPower": "وسيم",
    "fiftypecert": "شخص جميل",
    "cardRecoamnd": "بطاقة خضراء",
    "TodayExclusivecoupon": "ترخيص Greencard",
    "everyUpadate": "خصم 50 ٪ للبطاقة الخضراء",
    "to": "بطاقة خضراء تتلقى كوبونات خصم كل يوم.",
    "toUse": "خذ كل شيء اليوم.",
    "immeatallyGet": "تحديث يوميا في منتصف الليل",
    "weekBill": "لملء",
    "cardTip7": "قم بإعداد مشروع",
    "billTip": "احصل عليها الآن",
    "currentGoods": "هناك نقاط هذا الأسبوع.",
    "cardShop": "احصل عليها الآن",
    "cardBack": "بطاقة خضراء تتلقى كوبونات خصم كل يوم.",
    "cardSpecialTip": "عملية الشراء الحالية",
    "cardSpecial": "افتح التسوق مع البطاقة الخضراء",
    "cardPriseb": "التكامل العكسي هو",
    "cardPrisea": "بداية فورية للتكامل",
    "yearCard": "بطاقة خضراء خاصة",
    "openCards": "180 دولار",
    "onece": "88 دولار",
    "twice": "تمرير السنوي"
  },
  // 订单
  "order": {
    "outTimeGetMoney": "تعويض لأكثر من 10 دقائق",
    "inputForm": "وفاء النظام",
    "location": "حدد عنوانًا تريد الحصول عليه",
    "arrivalTime": "حان الوقت",
    "total": "يكمل!",
    "thing": "شئ ما",
    "wechatPay": "الدفع عن طريق البريد",
    "aliPay": "ودفع المال",
    "huabeiPay": "دفع",
    "use": "يستخدم",
    "order12": "أساسي",
    "mark": "ملحوظة",
    "tip": "يخبرنا ملء المعلومات بما تريده بشكل خاص؟",
    "goodsList": "قائمة المنتج",
    "totalMoney": "عدد القطع",
    "sendMoney": "شحن",
    "point": "أساسي",
    "order19": "دفعت فعلا",
    "pay": "دفعت فعلا",
    "sendForm": "ارسال الطلب",
    "selectArrivalTime": "اختر وقت التسليم",
    "comfirm": "يؤمن",
    "goods": "قائمة",
    "all": "يكمل!",
    "things": "شئ ما",
    "sigalPrice": "السعر فقط:",
    "numbers": "يحشد:",
    "addTip": "لا يزال ليس لدي عنوان لإضافة.",
    "addLocation": "أضف adress",
    "comfirmAndUse": "حفظ واستخدام",
    "editLocation": "تعديل العنوان",
    "myAddress": "عنواني",
    "settlement": "يوافق",
    "chooseGoods": "الرجاء تحديد عنصر من",
    "deliveryTime": "الرجاء تحديد وقت التسليم.",
    "today": "اليوم",
    "tomorrow": "صباح"
  },
  Login: {
    indexTitle: 'تسجيل',
    Daftar: {
      username: "اسم المستخدم",
      "title": "تسجيل",
      "phone": "الرجاء إدخال رقم هاتفك المحمول.",
      "code": "الرجاء إدخال رمز التأكيد.",
      "password": "من فضلك أدخل رقمك السري.",
      "againPassword": "الرجاء إدخال كلمة المرور الخاصة بك مرة أخرى.",
      "remenber": "تذكر كلمة المرور"
    },
    "Forgot": {
      "sendCode": "نشر",
      "confirm": "إرسال المعلومات",
      "goBack": "عُد"
    },
    "nameInput": "الرجاء إدخال رقم هاتفك المحمول.",
    "pwdInput": "من فضلك أدخل رقمك السري.",
    "remenber": "تذكر كلمة المرور",
    "loginButton": "تسجيل",
    "registrasi": "للتسجيل"
  },
  // 登录
  "login": {
    "title": "تسجيل",
    "phoneNumber": "رقم هاتف",
    "phoneNumberNotCorrect": "تنسيق رقم هاتف غير صالح",
    "phoneNumberNotEmpty": "رقم الهاتف غير متوفر",
    "passNumberNotEmpty": "يجب ألا تكون كلمة المرور فارغة.",
    "phoneInput": "يرجى إدخال رقم الهاتف الخاص بك.",
    "pass": "كلمة المرور",
    "passTip": "من فضلك أدخل رقمك السري.",
    "varify": "الرجاء إدخال رمز التأكيد.",
    "otherMethods": "طرق التسجيل الأخرى",
    "wechat": "سجل لقراءة رسائل البريد الإلكتروني",
    "qqchant": "قائمة قائمة انتظار قائمة الانتظار",
    "tipTile": "نصيحة جيدة:",
    "tipContent": "يتم تسجيل رقم الهاتف غير المسجل تلقائيًا عند التسجيل مع وكيل موافقة.",
    "tip": "بروتوكول المستخدم",
    "tipProcy": "استراتيجية حماية البيانات",
    "login": "تسجيل",
    "resgin": "للتسجيل",
    "passTip2": "الرجاء إدخال كلمة مرور (على الأقل 6 بت).",
    "sendVerify": "إرسال رمز التأكيد",
    "hasSend": "نشر",
    "phoneVerify": "رقم حساب تسجيل الدخول",
    "smsMessage": "فحص الأخبار المشفرة",
    "switchLoginMsg": "رقم حساب تسجيل الدخول",
    "messageSuccess": "تم إدخال كلمة المرور بنجاح ، يرجى إدخالها في حقل الإدخال.",
    "pleaseInputCorrectPhoneNumber": "يرجى إدخال رقم هاتف صالح.",
    "pleaseInputCorrectVerifyumber": "الرجاء إدخال رمز تأكيد صالح.",
    "otherWechat": "تسجيل البريد الإلكتروني لا يكتمل",
    "otherQQ": "قائمة انتظار مسجلة - لم تكتمل",
    "message": "التحقق من كلمة المرور:"
  },
  "Hint": {
    "notMore": "لم يعد",
    "loading": "تحميل",
    "phoneNot": "رقم الهاتف أو رقم الحساب غير متوفر",
    "SendCodeSuccess": "تم إرسال رمز التأكيد بنجاح!",
    "pwdInconformity": "كلمة مرور غير متسقة.",
    "notInput": "أدخل معلومات غير مكتملة.",
    "failed": "يفشل"
  },
  "newTab": {
    "home": "الصفحة الرئيسية",
    "money": "اعادة تعبئه",
    "order": "ترتيب",
    "my": "لي"
  },
  "tab": {
    "make_money": "استقبال"
  },
  Cart: {
    "zzInfo": {
      "buyIng": "يشتري",
      "buyFailed": "فشل شراء",
      "notLevel": "تفتقر إلى نقاط الأعضاء.",
      "buyJurisdiction1": "إذا لزم الأمر لشراء أمر ذهبي للأعضاء",
      "buyJurisdiction2": "إذا لزم الأمر لشراء أمر العضو البلاتيني",
      "buyJurisdiction3": "إذا لزم الأمر لشراء الماس المطلوب",
      "invite1": "شحن 3 أصدقاء لكسب المال معا.",
      "invite4": "دعوة 10 أصدقاء لكسب المال معًا.",
      "invite3": "شحن 30 صديقًا وكسب المال",
      "invite2": "لدعوة الأصدقاء",
      "myMoney": "رصيد حسابك",
      "under": "أدنى",
      "notSendMoney": "غير قابلة للتفاوض",
      "goTopUp": "يرجى تقديم تعزيزات.",
      "know": "يفهم",
      "account": "يوافق"
    },
    "hint": {
      "p1": "1. تتوفر مستويات عضوية مختلفة للشراء.مختلف",
      "p2": "2. كلما زاد عضو العضو ، كلما ارتفعت اللجنة.",
      "p3": "3. يمكنك عرض حالة الطلب في أي وقت للحصول على طلب."
    },
    "state": {
      "all": "الجميع",
      "going": "في تَقَدم",
      "paid": "مدفوع بالفعل",
      "frozen": "لا يزال",
      "over": "مكتمل",
      "canceled": "يلغي"
    },
    "info": {
      "allMoney": "إجمالي أصولي ฿",
      "dayAllOrder": "القائمة الكاملة اليوم",
      "dayTrustMoney": "تم تعيينه اليوم كيم",
      "yesterdayMoney": "نتيجة الأمس"
    },
    "text": {
      "one": "ترتيب"
    }
  },
  My: {
    "download": "تنزيل التطبيق",
    "personal": "معلومات شخصية",
    "recharge": "يملأ",
    "withdrawal": "أخرج المال",
    "language": "وضع اللغة",
    "logout": "يترك",
    "MemberInformation": {
      "balance": "رصيد البنك",
      "Withdraw": "أخرج المال",
      "Recharge": "اعادة تعبئه",
      "forzenAll": "قف"
    },
    PersonalInformation: {
      "Page": {
        "Avatar": "رأس",
        "Nickname": "كنية",
        "PhoneNumber": "رقم هاتف",
        "BankAccounts": "عنوان محفظتي",
        "Password": "كلمة المرور",
        "TransactionPassword": "عملية كلمة المرور",
        "title": "معلومات شخصية"
      },
      "portrait": {
        "SetAvatar": "رأس",
        "button": "صحيح"
      },
      "Name": {
        "ModifyNickname": "كنية",
        "title": "كنية",
        "name": "كنية",
        "Please": "الرجاء إدخال لقب تريد تحريره"
      },
      "Password": {
        "title": "تحرير رمز تسجيل الدخول",
        "PleaseEnter": "من فضلك أدخل رقمك السري.التي تريد تحريرها",
        "phone": "تليفون محمول",
        "NewPhone": "الرجاء إدخال رقم هاتفك المحمول.",
        "verificationCode": "التحقق من الشفرة",
        "verificationCodeNote": "الرجاء إدخال رمز التأكيد.",
        "ConfirmPassword": "كلمة المرور",
        "ConfirmPasswordNote": "الرجاء إدخال رؤى.التي تريد تحريرها",
        "Text": "يرجى تذكر كلمة المرور الخاصة بك ، إذا كنت قد نسيت كلمة المرور الخاصة بك ، يرجى الاتصال بخدمة العملاء.",
        "Button": "تأكيد المعالجة",
        "ButtonCode": "تلقي رمز التأكيد"
      },
      "AccountData": {
        "title": "تفاصيل التجارة"
      }
    }
  },
  "newAdd": {
    "all": "الجميع",
    "Completed": "مكتمل",
    "unfinished": "لم انتهي بعد",
    "Income": "دخل",
    "Expenditure": "التكاليف",
    "GetCodeBtn": "تلقي رمز التأكيد",
    "copySuccess": "نسخ بنجاح",
    "copyError": "فشل النسخ",
    "noLoginUserName": "تسجيل",
    "Withdraw": "أخرج المال",
    "Recharge": "اعادة تعبئه",
    "commission": "عمولة",
    "single": "ترتيب",
    "missionHall": "معمل",
    "videoTutorial": "دروس الفيديو",
    "helpCenter": "مركز المساعدة",
    "inviteFriends": "لدعوة الأصدقاء",
    "help1Title": "كيفية كسب العمولات حول CG",
    "help1Context": "يتعين على مالكي شركات الكهرباء زيادة المبيعات في الأعمال التجارية ، لذلك يتعين علينا فقط اتباع طلب عمل CG لإكمال الطلب ، يمنحنا المالك عمولة ، يمكن لكل مستخدم الحصول على 10 مهام يوميًا.يكسبون 10 ٪ إلى 30 ٪ عمولة خلال يوم واحد.",
    "help2Title": "كيفية زيادة عدد المهام",
    "help2Context": "نظرًا لوجود عدد كبير جدًا من الوظائف بدوام العمل ، فإن الشركة تدعو الموظفين.لا يتم استخدام جميع الوظائف بدوام جزئي في العمل إلا من قبل شخص واحد للاتصال والحصول على وظيفة.",
    "help3Title": "كيف أفعل العمل؟",
    "help3Context": "زيادة أرقام المبيعات والمكافآت وفقًا لحجم التداول لكل طلب ناجح ، ستتلقى عمولة.واكتسب البائع سمعة طيبة",
    "referralLink": "يوصي الروابط",
    "copy": "ينسخ",
    "paste": "دان",
    "blance": "توازن",
    "todayOrder": "أمر اليوم",
    "provided": "تم توفير هذه المعلومات",
    "Transaction": "يوافق",
    "Frozen": "الاضطرابات",
    "Done": "مكتمل",
    "Cancel": "يلغي",
    "ExpectedReturn": "العائد المقدر:",
    "ImmediatePayment": "الدفع الفوري",
    "AvailableNum": "كم مرة تستخدم",
    "OrderImmediately": "الآن في المجلد",
    "MakeMoneyEveryDay": "الدخل اليومي",
    "TodayRecord": "وصف الطلب",
    "AllocatCommissions": "عمولة",
    "FrozenAmount": "اتزان رائع",
    "TaskMatch": "المهام المختارة عشوائيا",
    "AvailableBalance": "الرصيد المتوفر",
    "WaitDispatch": "يرجى الاتصال بمدير الفرع لانتظار المهمة!",
    "OrderDescription": "وصف الطلب",
    "CouldMatch": "يتم تعيين المهام من قبل النظام:",
    "OrderPerDay": "يأمر.",
    "DescriptionRate": "الجمع بين عمولة لمبلغ الطلب.",
    "DescriptionPeriod": "。",
    "Description3": "يعتمد النظام على تكنولوجيا IBS.ويقترن تلقائيًا بالمنتجات عبر السحابة.",
    "Description4": "لكي لا يتم التلاعب بها إذا لم يتم تأكيد الطلب وتسليمه في غضون 20 دقيقة ، سيتم تعليق الطلب.",
    "Note": "ملحوظة:",
    "DescriptionNote": "إذا كان النظام الأساسي يطابق المستخدم بالترتيب ، فإن النظام الأساسي يرسل معلومات الطلب إلى الواجهة الخلفية للبائع.إذا لم يتخلى المستخدم عن طلب في غضون 20 دقيقة ، فسيتم تعليق مبلغ الطلب بواسطة النظام.يمكن أن تكسب الحسابات التي تفعل المهام عمولات.هذا يؤثر على وقت الطلب التالي وعلاقة اللجنة للمستخدم!يرجى الانتهاء من الإيداع والدفع في غضون الوقت بعد تلقي أمر العمل!",
    "OrderNum": "رقم الأمر:",
    "TotalOrderPrice": "السعر الكلي:",
    "SubmitNow": "أرسل على الفور",
    "Record": "تسجيل",
    "MemberAccount": "حساب الأعضاء",
    "AccountPlaceholder": "الرجاء إدخال حسابك.",
    "RechargeAmount": "تقصير",
    "RechargeAmountPlaceholder": "الرجاء إدخال مبلغ التحميل.",
    "SelectRechargeMethod": "اختر شكلاً من أشكال الشحن",
    "NotBeenSentOrder": "لم يتم إرسال الطلب ، يرجى الاتصال بالموظفين وإرسال الطلب!",
    "TodayOrderOver": "طلب بالفعل ، سأحاول مرة أخرى غدًا.",
    "NotLogin": "لم يتم تسجيل الدخول ، يرجى المحاولة مرة أخرى بعد تسجيل الدخول!",
    "HaveNoteMethod": "الافتتاح المقيد يرجى الاتصال بالمبيعات!",
    "Login": "تسجيل",
    "AccountRecord": "تغيير الحساب",
    "RealName": "الاسم الأول",
    "IFSCCODE": "ISK",
    "BankName": "اسم البطاقة المصرفية",
    "BankNum": "رقم البطاقة المصرفية",
    "Mobile": "تليفون محمول",
    "MobileNum": "تليفون محمول",
    "Email": "صندوق الرسالة",
    "EmailRequired": "يتم ملء موضوعات البريد.",
    "AddBankCardNote": "البطاقة المصرفية هي معلومات مهمة ، يرجى ملء بعناية!",
    "Submit": "إرسال المعلومات",
    "AddBankCardTitle": "أضف بطاقة مصرفية",
    "BankCard": "بطاقة مصرفية",
    "Name": "اسم العائلة؟",
    "CardNum": "رقم البطاقة",
    "Reading": "يقرأ ...",
    "AllBalance": "الكمية الإجمالية",
    "EnterWithdrawalAmount": "الرجاء إدخال المبلغ الإجمالي",
    "AllOut": "الجميع",
    "AskNow": "أخرج المال",
    "EnterPwdPlaceholder": "من فضلك أدخل رقمك السري.",
    "NotHaveCard": "لم تستخدم بطاقة نقدية بعد.هل ترغب في استخدام بطاقة مصرفية؟",
    "GetBankInfoFail": "لا يمكن استدعاء المعلومات المصرفية.",
    "EnterValidPayPwd": "الرجاء إدخال كلمة مرور دفع صالحة.",
    "Balance": "توازن",
    "Total": "الجميع",
    "total": "الجميع",
    "records": "تسجيل",
    "DisplayPerPage": "الوجه الرباعي",
    "NullNum": "حاجِز",
    "pages": "صفحة كتاب.",
    "toAudit": "بعد محاولة",
    "succeed": "ناجح",
    "RechargeRecordTitle": "يملأ",
    "rests": "آخر",
    "Arrive": "يذهب",
    "forzenCommission": "عمولة توقف",
    "companyIntro": "ملف الشركة",
    "companyTitle": "Tata Mall هو جزء من مجموعة Tata.",
    "warmTitle": "نصيحة جيدة",
    "companyText": "مجموعة Tata هي أكبر مجموعة من الشركات في الهند التي تأسست في عام 1997.1868 في مومباي ، شركة الأعمال في الهند استنادًا إلى جميع مجالات تكنولوجيا الاتصالات السبعة والمعلومات الهندسية ، وخدمات الطاقة ، والسلع الاستهلاكية.والمنتجات الكيميائية كمجموعة من الشركات التي تُعد مجموعة Tata Group هي الدولة النامية السريعة في الهند ولديها أكثر من 200 شركة في أكثر من 80 دولة في ست قارات.بلغ إجمالي معدل دورانه في السنة المالية 2021 100 مليون دولار و 58 ٪ منها جاء من الأعمال الدولية.توظف المجموعة حوالي 450،000 شخص في جميع أنحاء العالم منذ عام 153.يتمتع اسم Tata بسمعة عالية في الهند لأنه يلتزم بالقيم الجيدة وأخلاقيات العمل.الأفضل في عالم فوربس",
    "depositAddress": "عنوان الدفع المسبق",
    "rechargeSteps": "الشحن",
    "completePayment": "وأضاف",
    "rechargeNow": "وضعت على الفور",
    "rechargeCancel": "إلغاء التعبئة",
    "bankName": "حساب البنك",
    "payee": "تعيين الحساب",
    "cardNum": "بنك",
    "reviewing": "تحقق من مدى",
    "remittance": "تم تحويل المال",
    "notThrough": "يفشل",
    "OrderID": "معرف الأمر",
    "Amount": "رقم",
    "toCutScreen": "أنظر إلى الصور!",
    "cardTabBank": "بنك",
    "cardTabUSDT": "USDT",
    "name": "اسم العائلة",
    "chainType": "نوع سلسلة",
    "newNavTitle": "ارسال الطلب",
    "shopMoney": "عدد من المنتجات",
    "actualMoney": "حقا أرسل",
    "commitBtnText": "إنشاء النظام",
    "commitHint": "إدخال غير مكتمل"
  },
  // 我的
  "mine": {
    "servier": "رسوم إضافية",
    "integral": "أساسي",
    "balance": "تبادل",
    "record": "تبادل السجل",
    "wtdAmt": "نقاط الاسترداد",
    "avaBlc": "تبادل",
    "allWtd": "كل الاسترداد",
    "close": "يلغي",
    "commit": "يتأكد",
    "wtdHint": "الرجاء إدخال مبلغ دفع ساري المفعول."
  },
  "add": {
    "Commission": "عمولة",
    "Yesterday": "أمس",
    "Cumulative": "تقصير",
    "Today": "اليوم",
    "TeamNum": "الحجم الكلي للفريق TeamSpeak",
    "TeamMoney": "عمولة عامة",
    "Personalcenteraccountchangerecord": "بروتوكول تغيير الحساب",
    "lockedbalance": "اتزان رائع",
    "Cumulativeinvitation": "وقود",
    "Validinvitation": "فحص",
    "Accumulatedrebate": "تراكم"
  },
  "home.btn.wallet": "تمويل",
  "home.btn.recharge": "يرسل",
  "home.btn.withdraw": "تراجع",
  "home.btn.invite": "يدعو",
  "home.btn.message": "أخبار",
  "page.grab": "فقط",
  "orderCommission": "ترتيب",
  "commission": "عمولة",
  "todayIncome": "دخل اليوم",
  "personalEarnings": "دخل الناس",
  "completedOrders": "اضف إليه",
  "lockedOrders": "خزانة",
  "unfinishOrder": "لا مثيل له",
  "frozonAmount": "صندوق غير مستقر",
  "currentBalance": "الأرصدة الحالية",
  "page.mine": "تفاصيل الحساب",
  "mine.invitationCode": "المعرف المدعوين",
  "mine.serverCenter": "مركز الخادم",
  "mine.tools.total": "معاً",
  "mine.tools.financial": "تمويل",
  "mine.tools.details": "تفاصيل",
  "mine.tools.freeze": "اتزان رائع",
  "mine.serviceTools.personal": "شخصي",
  "mine.serviceTools.teamReport": "تقرير الفريق",
  "mine.serviceTools.invite": "حساب العائلة",
  "mine.serviceTools.message": "أخبار",
  "mine.serviceTools.aboutUs": "حول",
  "mine.serviceTools.security": "حماية",
  "mine.serviceTools.address": "عنوان",
  "mine.serviceTools.inviteTask": "العمل من أجل الاستخدام",
  "page.team": "فريق",
  "page.aboutus": "حول",
  "page.financial": "تمويل",
  "page.financialRecord": "التسجيل المالي",
  "hint.InternetErr": "أخطاء الشبكة ، يرجى المحاولة مرة أخرى لاحقًا!",
  "totalDeposit": "إجمالي الإيداع",
  "totalRevenue": "الدخل الإجمالي",
  "btn.transferInto": "تحويل",
  "btn.transferOut": "شفاف",
  "page.transferInto": "تحويل",
  "page.transferOut": "شفاف",
  "balanceTransfer": "النقل الميزانية العمومية",
  "availableBalance": "الائتمان الحالي",
  "depositRate": "مستودع",
  "days": "يوم - نهار",
  "hint.success": "ناجح",
  "hint.fail": "يفشل",
  "status.into": "تحويل",
  "status.out": "شفاف",
  "label.amount": "رقم",
  "label.time": "وقت",
  "Button": {
    "cancle": "يلغي",
    "confirm": "يتأكد"
  },
  "dialog.title.inputPaypass": "كلمة مرور سجل البيانات",
  "dialog.nopaypass": "الأشخاص الذين لديهم الكثير من الخبرة",
  "mine.serviceTools.lan": "لغة",
  "checkLans": "رمز اللغة",
  "mine.tools.commission": "عمولة",
  "page.commissionRecord": "عمولة",
  "noticeList.all": "الجميع",
  "noticeList.unread": "غير مقروء",
  "successWithdrawof": "التنفيذ الناجح",
  "home.title.notice": "ملحوظة",
  "home.title.patener": "براءة اختراع",
  "login.rememberPassword": "تذكر كلمة المرور",
  "page": {
    "cart": "سيارة",
    "order": "رقم",
    "financing": "تمويل",
    "goods": "منتج",
    "levelUpgrade": "ليفينزيفيل",
    "PlatformIntroduction": "- بالموقع",
    "RuleDescription": "أنظمة",
    "AgentCooperation": "التعاون الآسيوي",
    "CommonProblem": "مشكلة متكررة",
    "MemberUpgrade": "ترقية العضوية",
    "login": "تسجيل",
    "forgot": "ينسى",
    "registrasi": "المسجل",
    "record": "تسجيل",
    "Recharge": "حمولة",
    "inviteFriends": "صديق ضيف",
    "Withdraw": "ينسحب",
    "Service": "رسوم إضافية",
    "DialoguePage": "جانب الرسم البياني",
    "user": "شخصي",
    "RansferIn": "رانفيرين",
    "PersonalCenter": "المركز الشخصي",
    "Portrait": "رمز",
    "Name": "اسم العائلة",
    "Account": "حساب المستخدم",
    "Password": "كلمة المرور",
    "PaymentCode": "تمريرة الدفع",
    "Site": "ملكية",
    "AccountData": "بروتوكول تغيير الحساب",
    "RechargeRecord": "ترتيب نسخ",
    "WithdrawalRecord": "ترتيب العودة",
    "InviteFriends": "يدعو",
    "Announcement": "يعلن",
    "TheTeamReport": "تقرير الفريق",
    "BillRecord": "يأمر",
    "BankCardAdd": "أضف بطاقة مصرفية",
    "NewRecharge": "اعادة تعبئه",
    "RechargeList": "ترتيب نسخ",
    "newWithdraw": "ينسحب",
    "withdrawList": "ترتيب العودة",
    "SettingPayPwd": "تغيير كلمة مرور الدفع",
    "MakeMoney": "يكتسب نقود",
    "team": "فريق",
    "qrPay": "الدفع عبر رمز الاستجابة السريعة",
    "bankPay": "الدفع المصرفية",
    "aboutus": "عننا",
    "financial": "ادارة مالية",
    "financialRecord": "التسجيل المالي",
    "TransferInto": "تحويل",
    "TransferOut": "تحويل",
    "CommissionRecord": "لوح",
    "Security": "حماية",
    "Address": "عنوان",
    "Message": "معلومة",
    "InviteTask": "مهمة",
    "Notice": "يعلن",
    "modifyPaymentPassword": "تغيير كلمة مرور الدفع"
  },
  "teamReport": {
    "title": "تقرير الفريق",
    "subTitle": "نظرة عامة على الفريق الثانوي",
    "taskTitle": "دعوة الناس لتسجيل وإكمال عملية الشراء.",
    "bonus": "علاوة",
    "Unfinished": "يلغي",
    "Getted": "استلام"
  },
  "address": {
    "name": "اسم العائلة",
    "inputPlaceholder": "تفضل",
    "phone": "هاتف",
    "areaPlaceholer": "معلومات حول عنوان التسليم",
    "submit": "تسجيل"
  },
  "copyLink": "نسخ الوصلة",
  "Home.WithdrawSu.Balance": "توازن",
  "copy": "ينسخ",
  "My.PersonalInformation.Name.Modify": "تجديد",
  "password.old": "كلمة المرور القديمة",
  "password.old.placeholder": "سجل كلمة المرور",
  "password.confirmPassword": "مطالبة",
  "password.confirmPassword.placeholder": "أدخل كلمة المرور الجديدة مرة أخرى.",
  "hint.passwordDiff": "منافسة التوأم",
  "Login.Daftar.email": "بريد إلكتروني",
  "Login.emailInput": "تذكر إلى",
  "My.PersonalInformation.Password.email": "بريد إلكتروني",
  "My.PersonalInformation.Password.NewEmail": "الرجاء إدخال عنوان البريد الإلكتروني الخاص بك.",
  "mine.serviceTools.certify": "شهادة",
  "expectedEarningsTitle": "الربح المتوقع",
  "order.old": "مرتبة عالية الدخل",
  "order.new": "ترتيب منتظم",
  "loginType": {
    "phone": "هاتف",
    "email": "بريد إلكتروني"
  },
  "Certification": {
    "type": {
      "id": "بطاقة تعريف",
      "driver": "رخصة قيادة",
      "passport": "جواز سفر"
    },
    "name": "اسم العائلة",
    "license": "رقم الرخصة",
    "upload": "وثائقي",
    "submit": "إرسال المعلومات",
    "incompleteInformation": "مغازلة مع المعلومات وحاول مرة أخرى!",
    "submitSuccess": "مصادقة بيانات HashBase",
    "photo": "معرف الصورة",
    "review": "قيد المراجعة",
    "verified": "التحقق"
  },
  "upload": {
    "uploading": "رفع ...",
    "failed": "مستوى أعلى"
  },
  "viGrabDesces": {
    "1": "يمكن اختيار كل طلب كترتيب للسعادة وزيادة اللجنة إلى {معدل} ٪.يرجى الاتصال بمستشار الخدمة الميدانية الخاصة بك للتقدم للحصول على مزيد من المهام.",
    "2": "ساعد البائعين في إنشاء أوامر افتراضية لزيادة الرؤية وكسب العمولات",
    "3": "بعد تلقي طلبك ، يرجى الاتصال بالمعلم الخاص بك في أقرب وقت ممكن.نقل وإكمال بيانات الطلب.",
    "4": "العمل محدود يرجى ملاحظة عند قبول الطلب.",
    "5": "سيتم إصدار مبلغ السداد في غضون 5-10 دقائق بعد الانتهاء من المهمة.إذا كان لديك أي أسئلة ، فيرجى الاتصال بخدمة عملاء متخصصة أو عبر الإنترنت.",
    "6": "المهام التي تم تعيينها من قبل Shopee ، وتحسين مستوى VIP الخاص بك وكسب عمولات أعلى لوظائف Fackbook.",
    "7": "من خلال القيام بمهام Shopee المقصودة ، فإنك تكسب عمولات إضافية.لبعض الأقساط ، يرجى الاتصال بأخصائي إعلانات أو خدمة عملاء عبر الإنترنت."
  },
  "rechargeDesc": {
    "nav": "وصف التهمة",
    "p1": "لدينا الآن عدد كبير من الموظفين الذين يقومون بالمهام في نفس الوقت.",
    "p2": "لا يمكن للبائع الواحد تقديم حسابات كافية.",
    "p3": "لذلك ننشر حساب البائع لدينا في مكان آخر.",
    "p4": "إذا كنت ترغب في القيام بمهمة ، يمكنك الاتصال بالمبيعات لتسجيل طريقة الدفع الخاصة بك."
  },
  "chat": {
    "list": {
      "nav": "أخبار",
      "hours": "{H} ساعة",
      "minutes": "{m} دقيقة",
      "ago": "{time} مسبقا",
      "image": "صورة"
    },
    "room": {
      "msgPlaceholder": "اكتب رسالة ...",
      "send": "يرسل",
      "sender": "مرسل",
      "sendTime": "أرسل الوقت",
      "content": "محتويات"
    }
  },
  "newBank": {
    "usdt": {
      "name": "اسم العائلة",
      "currency": "عملة",
      "chainType": "نوع سلسلة",
      "depositAddress": "عنوان الإيداع",
      "submit": "يرسل",
      "bank": "بنك",
      "wallet": "محفظة",
      "bankName": "اسم البنك",
      "bankCard": "بطاقة بنكية"
    },
    "walletList": {
      "pageTitle": "إدارة الجيب الاصطناعي",
      "name": "الاسم المستعار للمحفظة:",
      "coinType": "عملة:",
      "chainType": "نوع السلسلة:",
      "walletAddress": "عنوان:",
      "addDate": "تاريخ:",
      "btnText": "أضف عنوان العنصر",
      "deleteConfirmText": "هل ترغب حقًا في حذف عنوان المحفظة؟",
      "btnConfirm": "حذف",
      "btnCancle": "يلغي",
      "successHint": "ناجح"
    },
    "withdrawNum": "مبلغ الدفع",
    "AllOut": "الجميع",
    "balance": "الميزانية العمومية العامة",
    "handlingFee": "رسوم إدارية",
    "actualAmount": "مبلغ حقيقي"
  },
  "hashPlaceholder": "الرجاء إدخال التجزئة",
  "recharge.status.error": "فشل إنشاء الطلب الافتراضي Amazon ، يرجى إرسال قيمة التجزئة الصحيحة ومبلغ العنصر",
  "recharge.actualAmount": "الحساب الفعلي",
  "login.userAgreement": "اتفاقية المستخدم",
  "login.title1": "الغناء في",
  "login.title2": "مرحبًا بعودتك!",
  "registe.title1": "التسجيل",
  "registe.title2": "مرحبًا بعودتك!",
  "Login.Daftar.selectCurrency": "اختر العملة",
  "mine.totalBalance": "التوازن الكلي",
  "login.forgetPassword": "نسيت كلمة المرور الخاصة بك ، يرجى الاتصال بخدمة العملاء!",
  "Home.HallofFame": "قاعة الشهرة",
  "btn.Identify": "التعرف على الفور",
  "My.service": "خدمة",
  "mine.credit": "ائتمان",
  "page.checkin": "تحقق في",
  "checkin.submit": "تحقق في",
  "checkin.success": "تسجيل الدخول بنجاح",
  "checkin.fail": "فشل تسجيل الدخول",
  "todayChecked": "لقد قمت بتسجيل الدخول ، من فضلك لا تكرر العملية!",
  "submit.checked": "تم الدخول",
  "hint.inviteBanned": "عذرًا ، لم يفتح حسابك حسابًا عائليًا بعد ، يرجى الاتصال بخدمة العملاء أو مرشدك لتقديم طلب فتح!",
  "bannerText1": "{name} الكامل {Money} مهمة للحصول على {Money1}",
  "bannerText2": "تهانينا {name} حدد المهمة المميزة للحصول على عمولة 30 ٪",
  "grap": {
    "btask": "مهام المكافأة",
    "wtask": "مهام العمل"
  },
  "new_goods": {
    "custdown": "العد التنازلي",
    "hours": "ساعات",
    "minutes": "دقائق",
    "seconds": "ثوان",
    "descBtn": "وصف المهمة",
    "taskBtn": "اكمل المهمة"
  },
  "gift": {
    "gift_shop": "متجر الهدايا",
    "my_gift": "هديتى",
    "away": "يتبرع",
    "buy": "شراء",
    "overlay": {
      "inputPlaceholder": "أدخل رقم الغرفة",
      "num": "عدد الهدايا",
      "room": "هدية رقم الغرفة",
      "no_data": "لا هدية"
    },
    "detailTitle": "سجل الهدايا",
    "num": "كمية",
    "room": "رقم الغرفة"
  },
  "p2p": {
    "trade_method": {
      "buy": "شراء",
      "sell": "بيع"
    },
    "coinList": {
      "orders": "طلبات {__}",
      "label": {
        "amount": "المبلغ",
        "price": "السعر",
        "limit": "الحد"
      }
    },
    "tradeDetails": {
      "trade_title_sell": "كم عدد {__} ترغب في بيعها؟",
      "trade_title_buy": "كم عدد {__} ترغب في شرائها؟",
      "submit_now": "قدّم {__} الآن"
    },
    "orderDetails": {
      "sellTitle": "في انتظار الدفع من الطرف الآخر",
      "buyTitle": "في انتظار دفعك",
      "sellHint": "يجب على الطرف الآخر إكمال الدفع في غضون {__}، وإلا سيتم إلغاء الطلب تلقائيًا.",
      "buyHint": "أكمل الدفع في غضون {__}. بعد إكمال الدفع، انقر على الاتصال بالبائع",
      "payInfo_title": "معلومات الدفع",
      "label": {
        "totalAmount": "المبلغ الإجمالي",
        "pricePerUnit": "السعر للوحدة الواحدة",
        "quantity": "الكمية",
        "counterpaty": "الطرف الآخر",
        "payMethod": "طريقة الدفع",
        "fullName": "الاسم الكامل",
        "email": "البريد الإلكتروني",
        "phone": "رقم الهاتف"
      },
      "btn_service": "الاتصال بالبائع",
      "btn_sell": "الاتصال بالمشتري"
    },
    "limitHint": {
      "notEnough": "فشل في الطلب، البائع ليس لديه ما يكفي من USDT",
      "less": "فشل في الطلب، الحد الأدنى للتداول {__}",
      "more": "فشل في الطلب، الحد الأقصى للتداول {__}"
    }
  }
}